@import "./_index.scss";
@import "./_fonts.scss";
@import "./table.scss";
@import "./_color.scss";

// Important Bootstrap Imports
// **[Required]**
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
// @import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";

// Importing Individual SCSS
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/utilities/api";
// @import "~egi-ui-component/css/main";

:root {
  --aqua-1: rgb(36, 180, 179);
  --aqua-2: rgb(225, 255, 255);
  --aqua-4: rgb(214, 255, 241);
  --aqua-6: rgb(79, 204, 190);
  --aqua-7: rgb(128, 225, 215);
  --aqua-8: rgb(4, 98, 112);
  --aqua-9: rgb(0, 70, 79);
  --coral-1: rgb(255, 118, 113);
  --coral-2: rgb(255, 236, 233);
  --coral-4: rgb(255, 167, 150);
  --coral-5: rgb(250, 137, 135);
  --coral-6: rgb(232, 111, 111);
  --coral-7: rgb(221, 77, 84);
  --mustard-1: rgb(237, 191, 85);
  --mustard-2: rgb(242, 223, 181);
  --gray-1: rgb(233, 248, 248);
  --gray-2: rgb(196, 211, 211);
  --gray-3: rgb(141, 156, 156);
  --gray-4: rgb(88, 103, 103);
}
.bg-aqua-1 {
  background-color: #24b4b3 !important;
}
.bg-aqua-2 {
  background-color: #e1ffff !important;
}
.bg-aqua-4 {
  background-color: #d6fff1 !important;
}
.bg-aqua-6 {
  background-color: #4fccbe !important;
}
.bg-aqua-7 {
  background-color: #80e1d7 !important;
}
.bg-aqua-8 {
  background-color: #046270 !important;
}
.bg-aqua-9 {
  background-color: #00464f !important;
}
.bg-coral-1 {
  background-color: #ff7671 !important;
}
.bg-coral-2 {
  background-color: #ffece9 !important;
}
.bg-coral-4 {
  background-color: #ffa796 !important;
}
.bg-coral-5 {
  background-color: #fa8987 !important;
}
.bg-coral-6 {
  background-color: #e86f6f !important;
}
.bg-coral-7 {
  background-color: #dd4d54 !important;
}
.bg-mustard-1 {
  background-color: #edbf55 !important;
}
.bg-mustard-2 {
  background-color: #f2dfb5 !important;
}
.bg-gray-1 {
  background-color: #e9f8f8 !important;
}
.bg-gray-2 {
  background-color: #c4d3d3 !important;
}
.bg-gray-3 {
  background-color: #8d9c9c !important;
}
.bg-gray-4 {
  background-color: #586767 !important;
}
.text-aqua-1 {
  color: #24b4b3 !important;
}
.text-aqua-2 {
  color: #e1ffff !important;
}
.text-aqua-4 {
  color: #d6fff1 !important;
}
.text-aqua-6 {
  color: #4fccbe !important;
}
.text-aqua-7 {
  color: #80e1d7 !important;
}
.text-aqua-8 {
  color: #046270 !important;
}
.text-aqua-9 {
  color: #00464f !important;
}
.text-coral-1 {
  color: #ff7671 !important;
}
.text-coral-2 {
  color: #ffece9 !important;
}
.text-coral-4 {
  color: #ffa796 !important;
}
.text-coral-5 {
  color: #fa8987 !important;
}
.text-coral-6 {
  color: #e86f6f !important;
}
.text-coral-7 {
  color: #dd4d54 !important;
}
.text-mustard-1 {
  color: #edbf55 !important;
}
.text-mustard-2 {
  color: #f2dfb5 !important;
}
.text-gray-1 {
  color: #e9f8f8 !important;
}
.text-gray-2 {
  color: #c4d3d3 !important;
}
.text-gray-3 {
  color: #8d9c9c !important;
}
.text-gray-4 {
  color: #586767 !important;
}
.border-color-aqua-1 {
  border-color: #24b4b3 !important;
}
.border-color-aqua-2 {
  border-color: #e1ffff !important;
}
.border-color-aqua-4 {
  border-color: #d6fff1 !important;
}
.border-color-aqua-6 {
  border-color: #4fccbe !important;
}
.border-color-aqua-7 {
  border-color: #80e1d7 !important;
}
.border-color-aqua-8 {
  border-color: #046270 !important;
}
.border-color-aqua-9 {
  border-color: #00464f !important;
}
.border-color-coral-1 {
  border-color: #ff7671 !important;
}
.border-color-coral-2 {
  border-color: #ffece9 !important;
}
.border-color-coral-4 {
  border-color: #ffa796 !important;
}
.border-color-coral-5 {
  border-color: #fa8987 !important;
}
.border-color-coral-6 {
  border-color: #e86f6f !important;
}
.border-color-coral-7 {
  border-color: #dd4d54 !important;
}
.border-color-mustard-1 {
  border-color: #edbf55 !important;
}
.border-color-mustard-2 {
  border-color: #f2dfb5 !important;
}
.border-color-gray-1 {
  border-color: #e9f8f8 !important;
}
.border-color-gray-2 {
  border-color: #c4d3d3 !important;
}
.border-color-gray-3 {
  border-color: #8d9c9c !important;
}
.border-color-gray-4 {
  border-color: #586767 !important;
}
.border-aqua-1 {
  border: 1px solid #24b4b3 !important;
}
.border-aqua-2 {
  border: 1px solid #e1ffff !important;
}
.border-aqua-4 {
  border: 1px solid #d6fff1 !important;
}
.border-aqua-6 {
  border: 1px solid #4fccbe !important;
}
.border-aqua-7 {
  border: 1px solid #80e1d7 !important;
}
.border-aqua-8 {
  border: 1px solid #046270 !important;
}
.border-aqua-9 {
  border: 1px solid #00464f !important;
}
.border-coral-1 {
  border: 1px solid #ff7671 !important;
}
.border-coral-2 {
  border: 1px solid #ffece9 !important;
}
.border-coral-4 {
  border: 1px solid #ffa796 !important;
}
.border-coral-5 {
  border: 1px solid #fa8987 !important;
}
.border-coral-6 {
  border: 1px solid #e86f6f !important;
}
.border-coral-7 {
  border: 1px solid #dd4d54 !important;
}
.border-mustard-1 {
  border: 1px solid #edbf55 !important;
}
.border-mustard-2 {
  border: 1px solid #f2dfb5 !important;
}
.border-gray-1 {
  border: 1px solid #e9f8f8 !important;
}
.border-gray-2 {
  border: 1px solid #c4d3d3 !important;
}
.border-gray-3 {
  border: 1px solid #8d9c9c !important;
}
.border-gray-4 {
  border: 1px solid #586767 !important;
}
.zuno-header {
  background-color: #fff !important;
  color: var(--aqua-9) !important;
}
.zuno-footer {
  background-color: var(--aqua-1) !important;
  color: #fff !important;
}
