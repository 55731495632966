$colors: (
  blue: "#034EA2",
  yellow: "#FAB218",
  pink: "#EC1968",
  grey: "#6D6E71",
  black: "#000",
);

@each $item, $color in $colors {
  .color-#{$item} {
    color: #{$color};
  }
}
