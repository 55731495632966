$PrimaryColor: var(--aqua-9);

:root {
  --primary: var(--aqua-9);
  --primary-hover: var(--aqua-9);
  --secondary: var(--coral-1);
  --gray: #b3b4b4;
  --yellow: #fab218;
  --white: #fff;
}
$fontmedium: "Roboto-Medium";
$fontRegular: "Roboto-Regular";

#root {
  display: flex;
  flex-direction: column;
  min-block-size: 100vh;

  main {
    flex: 1;
  }
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

h2,
h1,
h3,
h4 {
  color: var(--primary);
}

h2,
p {
  margin: 0.83em 0em;
  color: var(--aqua-1);
}

body {
  background-color: #f4f7fc;
  font-family: "Roboto-Regular";
}

main {
  padding: 1.25rem 5rem;
  background-color: #fff;
}

.header {
  background-color: #ffffff;
  padding: 1.1rem 0rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: max-content;

  .navbar-div {
    display: flex;
    text-transform: uppercase;
    gap: 0.75rem;

    .logout-icon {
      margin-right: 0.25rem;
      align-items: center;
    }
    .navbar {
      ul {
        list-style-type: none;
        display: flex;
        align-items: center;
        margin-top: 1.125rem;

        li {
          display: inline-block;
          margin-right: 1rem;
          white-space: nowrap;

          a {
            font-family: "Roboto-Medium";
            text-decoration: none;
            font-size: 16px;
            position: relative;
            cursor: pointer;
            color: #000000;
            text-transform: none;
            &:after {
              content: "";
              height: 3px;
              width: 0;
              background-color: $PrimaryColor;

              position: absolute;
              left: 0;
              bottom: -2.688rem;
              transition: 0.3s;
            }
            &:hover {
              color: $PrimaryColor;
              text-decoration: none;
            }
            &:hover::after {
              width: 100%;
            }
          }
          .active {
            color: $PrimaryColor;
            text-decoration: none;
          }
          .active::after {
            width: 100%;
          }
        }
      }
    }
  }

  .edl-logo {
    a {
      text-decoration: none;
      display: flex;
      align-items: center;
      // gap: 0.5rem;
    }
    .motor-logo {
      font-family: "Roboto-Bold";
      color: #818e94;
      font-size: 24px;
      .cms-logo {
        font-family: "Roboto-Bold";
        color: #0b3ba7;
      }
    }
  }
}

// Navbarscss
.navbar-box-horizontal {
  width: 100%;
  display: flex;
  border-bottom: 1px solid rgba($color: #000000, $alpha: 0.3);
  justify-content: space-between;
  overflow-x: auto;
  .navbar-item {
    position: relative;
    width: 100%;
    padding: 0.75rem 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.1s;
    &:hover {
      border-bottom: 2px solid $PrimaryColor;
    }
    a {
      font-family: $fontmedium;
      color: #2e2f3b;
      text-decoration: none;
    }
  }
  .active {
    position: relative;
    border-bottom: 2px solid var(--primary);

    &::after {
      content: "";
      position: absolute;
      --size: 4px;
      height: var(--size);
      width: var(--size);
      border-radius: 50%;
      background: var(--yellow);
      top: 5px;
      right: 5px;
    }
  }
}

.navbar-item {
  position: relative;
  width: 100%;
  padding: 0.75rem 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.1s;

  &:hover {
    border-bottom: 2px solid $PrimaryColor;
  }

  a {
    font-family: $fontmedium;
    color: #2e2f3b;
    text-decoration: none;
  }
}

/* Sticky Vertical Navbar */
.sticky-navbar {
  position: fixed;
  top: 110px;
  left: 0;
  width: 200px;
  height: 100vh;
  background: white;
  box-shadow: 2px 0 5px rgba(44, 39, 39, 0.1);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  transition: transform 0.3s ease-in-out;
  z-index: 1000;

  .navbar-item {
    justify-content: flex-start;
    padding: 1rem;
    a {
      font-family: $fontmedium;
      color: #2e2f3b;
      text-decoration: none;
    }
  }
  .active {
    position: relative;
    border-bottom: 2px solid var(--primary);

    &::after {
      content: "";
      position: absolute;
      --size: 4px;
      height: var(--size);
      width: var(--size);
      border-radius: 50%;
      background: var(--yellow);
      top: 5px;
      right: 5px;
    }
  }
}

.hidden {
  transform: translateX(-100%);
}

@media (max-width: 1024px) {
  .sticky-navbar {
    display: none !important;
  }
}

//Assessment Navbar css
.assessment-navbar-box {
  width: 100%;
  display: flex;
  border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
  overflow-y: auto;

  justify-content: space-between;
  .navbar-item {
    position: relative;
    width: 100%;
    padding: 0.75rem 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.1s;
    &:hover {
      // border-bottom: 2px solid $PrimaryColor;
      border-bottom: 2px solid var(--aqua-1);
    }
    a {
      font-family: $fontRegular;
      color: #2e2f3b;
      text-decoration: none;
    }
  }
  .active {
    position: relative;
    // border-bottom: 2px solid var(--primary);
    border-bottom: 2px solid var(--aqua-1);

    &::after {
      content: "";
      position: absolute;
      --size: 4px;
      height: var(--size);
      width: var(--size);
      border-radius: 50%;
      background: var(--yellow);
      top: 5px;
      right: 5px;
    }
  }
}

.menu-dropdown {
  color: #383838 !important;
  font-family: "Roboto-Medium" !important;
  text-transform: capitalize !important;
  & > a {
    text-decoration: none !important;
    color: #383838 !important;
  }
  &:hover {
    color: #fff !important;
  }
}

.menu-dropdown:hover .logout-icon {
  color: #fff !important;
}
// whight-box scss
.white-box {
  width: 100%;
  padding: 1.5rem;
  background-color: #fff;
  box-shadow: 0px 3px 14px var(--aqua-1);
  border-radius: 13px;
}
h3 {
  font-size: 25px;
  font-family: "Roboto-Bold";
  color: var(--primary);
  margin-bottom: 1rem;
}

h1,
h2,
h4 {
  font-family: "Roboto-Bold";
}

// Custom DatePicker

.datepicker-wrapper {
  position: relative;
  overflow: hidden;

  &:hover {
    border-color: var(--aqua-9);
  }

  .MuiInputAdornment-root {
    position: absolute;
    background: var(--primary);
    top: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 52px;
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: none;
    margin-left: 0;

    .MuiIconButton-root {
      font-size: 1.125rem;
      margin-right: 0;
      padding: 1rem;
      color: #fff;
    }
  }
}

// DatePicker
.MuiInputAdornment-root {
  .MuiIconButton-root {
    background: var(--primary);
    border-radius: 0;
    margin-right: -13px;
    height: 54px;
    width: 54px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;

    &:hover {
      background: var(--primary-hover);
    }

    svg {
      color: #fff;
    }
  }
}

.uppercase-input {
  text-transform: uppercase;
}

.profile-base {
  padding: 5px 12px;
  border-radius: 10px;
  box-shadow: 0px 3px 54px #bfddff8a;
  text-transform: capitalize !important;
  cursor: pointer;
}

.text-link {
  color: var(--primary);
  cursor: pointer;
  width: max-content;
}

// ::-webkit-scrollbar {
//   width: 0.4rem;
// }
// ::-webkit-scrollbar-track {
//   background-color: var(--gray);
// }
// ::-webkit-scrollbar-thumb {
//   // box-shadow: inset 0 0 6px var(--secondary);
//   background-color: var(--secondary);
// }
.text-error {
  color: black !important;
  font-weight: 400;
  font-size: 0.75rem;
}
.blink_me {
  animation: blinker 1.5s linear infinite;
  color: black !important;
}

@keyframes blinker {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.7;
  }

  100% {
    opacity: 0;
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

// li {
//   color: var(--aqua-1);
// }

// .MuiOutlinedInput-notchedOutline {
//   border-color: var(--aqua-9) !important;
// }

.MuiOutlinedInput-root {
  &:hover {
    .MuiOutlinedInput-notchedOutline {
      border-color: var(--secondary) !important;
    }
  }

  // *:disabled {
  //   background-color: rgb(221, 217, 217);
  // }
}

.profile-section-mobile,
.navbar-mobile {
  display: none;
}
.profile-section-desktop,
.navbar-desktop,
.header-claim-number {
  display: block;
}

.table-manage {
  overflow-x: auto;
}
.navbar-box {
  overflow-x: auto;
}

.mobile-box-manage {
  display: flex;
  justify-content: space-between;
  gap: 16px;
}

.mail-model-btn {
  display: flex;
  justify-content: flex-end;
}
.mail-action-btn {
  width: 20% !important;
}

.confirm-mail-model-btn-main {
  display: flex;
  justify-content: flex-end;
}
.confirm-mail-model-btns {
  display: flex;
}
.confirm-mail-btn {
  margin-left: 20px;
}
.confirm-mail-btn-action {
  width: 250px;
}
.custom-mail-btn {
  width: 250px;
}
.margin-bottom-20 {
  margin-bottom: 20px !important;
}
.margin-bottom-22 {
  margin-bottom: 22px !important;
}

.block-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.block-header-action {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
}

.search-box {
  display: flex;
  align-items: center;
}
.emailpopup_title_date {
  display: flex;
  justify-content: space-between;
}
.longstring-manage {
  overflow-wrap: anywhere !important;
}

@media (max-width: 280px) {
  .block-header {
    display: block;
  }
  .block-header-action {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 578px) {
  .search-btn {
    margin-top: 20px !important;
  }
  .business-search {
    margin-top: 3px !important;
  }
  .emailpopup_title_date {
    display: block;
    margin-bottom: 10px;
  }
  .item-font-14 {
    font-size: 14px !important;
  }
}

@media (max-width: 768px) {
  .header-title {
    font-size: 22px;
  }
  .item-list-font {
    font-size: 16px;
  }
  .header {
    display: block;
  }
  .edl-logo {
    display: flex;
    align-items: center;
  }
  .profile-section-mobile {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
  .profile-section-desktop {
    display: none;
  }
  .navbar-mobile {
    display: flex;
    justify-content: space-between;
    width: 98%;
    margin-top: 14px;
    height: 40px;
    align-items: center;
  }
  .mobile-menu-item {
    text-decoration: none;
    font-size: 16px;
    cursor: pointer;
    color: #000000;
    text-transform: none;
  }
  .mobile-menu-item.active {
    color: var(--aqua-9);
  }
  .navbar-desktop,
  .header-claim-number {
    display: none;
  }
  .profile-base-mobile {
    padding: 0px 8px;
    border-radius: 10px;
    box-shadow: 0px 0px 40px #bfddff8a;
    text-transform: capitalize !important;
    cursor: pointer;
  }
  main {
    padding: 1.25rem 0rem;
  }
  .mobile-or {
    margin-top: 16px !important;
    margin-bottom: 8px !important;
  }

  // .navbar-box{
  //   overflow-x: auto;
  // }
  .margin-top-20 {
    margin-top: 20px !important;
  }
  .margin-top-4 {
    margin-top: 4px !important;
  }
  .vehicle-reg-number {
    margin-bottom: 15px !important;
  }
  .model-title {
    font-size: 20px !important;
  }
  .model-title-25 {
    font-size: 25px !important;
  }
  .model-title-16 {
    font-size: 16px !important;
  }
  .MuiDialog-paper {
    margin: 0px !important;
    // max-height: 600px !important;
  }
  .white-box-mobile {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  // .row-box-lr-padding{
  //   padding-left: 0px !important;
  //   padding-right: 0px !important;
  // }
  // .row-box-r-padding{
  //   padding-right: 0px !important;
  // }
  .mobile-box-manage {
    display: block;
  }
  .confirm-mail-model-btn {
    display: block;
  }
  .confirm-mail-model-btn-main {
    width: 100%;
    display: block;
  }
  .confirm-mail-model-btns {
    display: block;
  }
  .confirm-mail-btn {
    width: 100%;
    margin-left: 0px;
  }
  .confirm-mail-btn-action {
    width: 100%;
  }
  .mail-action-btn {
    width: 100% !important;
  }
  .attached-document {
    margin: 5px !important;
  }
  .custom-mail-btn {
    width: 100%;
  }
}

@media (max-width: 992px) {
  .search-btn {
    margin-top: 20px !important;
  }
  .mobile-or {
    margin-top: 16px !important;
    margin-bottom: 8px !important;
  }
}

@media (min-width: 820px) and (max-width: 822px) {
  .header {
    display: block;
  }
  .edl-logo {
    display: flex;
    align-items: center;
  }
  .profile-section-mobile {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
  .profile-section-desktop {
    display: none;
  }
  .navbar-mobile {
    display: flex;
    justify-content: space-between;
    width: 98%;
    margin-top: 14px;
    height: 40px;
    align-items: center;
  }
  .mobile-menu-item {
    text-decoration: none;
    font-size: 16px;
    cursor: pointer;
    color: #000000;
    text-transform: none;
  }
  .mobile-menu-item.active {
    color: var(--aqua-9);
  }
  .navbar-desktop,
  .header-claim-number {
    display: none;
  }
  .profile-base-mobile {
    padding: 0px 8px;
    border-radius: 10px;
    box-shadow: 0px 0px 40px #bfddff8a;
    text-transform: capitalize !important;
    cursor: pointer;
  }
}

@media (min-width: 844px) and (max-width: 851px) {
  .header {
    display: block;
  }
  .edl-logo {
    display: flex;
    align-items: center;
  }
  .profile-section-mobile {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
  .profile-section-desktop {
    display: none;
  }
  .navbar-mobile {
    display: flex;
    justify-content: space-between;
    width: 98%;
    margin-top: 14px;
    height: 40px;
    align-items: center;
  }
  .mobile-menu-item {
    text-decoration: none;
    font-size: 16px;
    cursor: pointer;
    color: #000000;
    text-transform: none;
  }
  .mobile-menu-item.active {
    color: var(--aqua-9);
  }
  .navbar-desktop,
  .header-claim-number {
    display: none;
  }
  .profile-base-mobile {
    padding: 0px 8px;
    border-radius: 10px;
    box-shadow: 0px 0px 40px #bfddff8a;
    text-transform: capitalize !important;
    cursor: pointer;
  }
}

@media (min-width: 896px) and (max-width: 915px) {
  .header {
    display: block;
  }
  .edl-logo {
    display: flex;
    align-items: center;
  }
  .profile-section-mobile {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
  .profile-section-desktop {
    display: none;
  }
  .navbar-mobile {
    display: flex;
    justify-content: space-between;
    width: 98%;
    margin-top: 14px;
    height: 40px;
    align-items: center;
  }
  .mobile-menu-item {
    text-decoration: none;
    font-size: 16px;
    cursor: pointer;
    color: #000000;
    text-transform: none;
  }
  .mobile-menu-item.active {
    color: var(--aqua-9);
  }
  .navbar-desktop,
  .header-claim-number {
    display: none;
  }
  .profile-base-mobile {
    padding: 0px 8px;
    border-radius: 10px;
    box-shadow: 0px 0px 40px #bfddff8a;
    text-transform: capitalize !important;
    cursor: pointer;
  }
  .item-list-font {
    font-size: 18px;
  }
}

@media (min-width: 1024px) and (max-width: 1025px) {
  .item-list-font {
    font-size: 18px;
  }
}

@media (max-width: 1280px) {
  .button-bgcolor-mobile-diabled {
    background-color: rgba(0, 0, 0, 0.12) !important;
    color: rgba(0, 0, 0, 0.26) !important;
  }
  .text-white-color {
    color: #ffffff !important;
  }
}
