@font-face {
  font-family: "Roboto-Bold";
  src: local("Roboto-Bold"), url("../fonts/Roboto-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Roboto-Medium";
  src: local("Roboto-Medium"),
    url("../fonts/Roboto-Medium.ttf") format("truetype");
  font-weight: medium;
}

@font-face {
  font-family: "Roboto-Regular";
  src: local("Roboto-Regular"),
    url("../fonts/Roboto-Regular.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "helvitica-45-light";
  src: url("../fonts/fontscore.com_h_helvetica-45-light.ttf"),
    format("truetype");
}
