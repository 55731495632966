.table {
  border-collapse: collapse;
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 3px 14px var(--aqua-1);

  margin-top: 2rem;
  & > thead {
    & > tr:hover {
      background: #f6f9fd;
    }
    & > tr th {
      font-family: "Roboto-Bold";
      padding: 8px;
      text-align: center;
      color: var(--aqua-9);
      padding-top: 12px;
      padding-bottom: 12px;
      text-align: center;
      border: 1px solid var(--aqua-1);
    }
  }
  & > tbody {
    & > tr:hover {
      background: #f6f9fd;
    }
    & > tr td,
    & > tr th {
      border-bottom: 1px solid #ddd;
      padding: 8px;
      text-align: center;
      border: 1px solid var(--aqua-1);
    }
    tr {
      td {
        a {
          color: var(--aqua-9);
          font-weight: 700;
          text-decoration: none;
        }
      }
    }
  }
}
